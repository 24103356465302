/* eslint-disable no-unused-vars */
export const API_URL = process.env.REACT_APP_API_URL || '';

export enum ApiPath {
  ADMIN_CUSTOMERS = '/api/admin/customers',
  ADMIN_CUSTOMER_DETAIL = '/api/admin/customers/detail',
  USER = '/api/v1/users',
  LOGIN = '/api/auth/login',
  REGISTER = '/api/v1/register',
  TODO = '/api/v1/todos',
  RESET_PASSWORD = '/api/v1/reset_password',
  UPDATE_PASSWORD = '/api/v1/update_password',
  PUBLIC_AGREEMENTS = '/api/public/agreements',
  PUBLIC_MAKERS = '/api/public/makers',
  PUBLIC_PRODUCT_MODELS = '/api/public/product-models',
  PUBLIC_PRODUCT_CATEGORIES = '/api/public/product-categories',
  PUBLIC_REPAIRS = '/api/public/repairs',
  DOCUMENTS = '/documents',
  REPAIR = '/repair',
  STATUS = '/status',
  CONFIRM = '/confirm',
  DOWNLOAD_PDF_FILE = '/api/public/repairs/download',
  ADMIN_USERS = '/api/admin/users',
  ADMIN_POSITIONS = '/api/admin/positions',
  CUSTOMER_LOGIN = '/api/auth/customer/login',
  CUSTOMER_GET_ME = '/api/customer',
  ADMIN_GET_ME = '/api/admin',
  ADMIN_REPAIRS = '/api/admin/repairs',
  ADMIN_REPAIRS_AGREEMENTS = '/api/admin/repair-agreements',
  ADMIN_PRODUCT_CATEGORIES = '/api/admin/product-categories',
  ADMIN_PRODUCT_MODELS = '/api/admin/product-models',
  ADMIN_MAKERS = '/api/admin/makers',
  CUSTOMER_AGREEMENTS = '/api/customer/agreements',
  CUSTOMER_AGREEMENT_HISTORY = '/api/customer/customer-history',
  CUSTOMER_REPAIRS = '/api/customer/repairs',
  CUSTOMER_NOT_READ_AGREEMENT_COUNT = '/api/customer/insurance-claim-agreements/count-agreement-not-yet-read'
}
