import React, { useMemo } from 'react';
import {
  GroupIcon,
  Note2Icon,
  NoteIcon,
  SettingIcon,
  ToolIcon
} from 'src/assets/icons';
import { RoutePath } from 'src/enums/routePath';
import { unSlash } from 'src/utils/route';
import { Menu, MenuProps, styled } from 'core-ui-2';
import { Button } from 'core-ui';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { isArray } from 'lodash';

const menuItems: MenuProps['items'] = [
  {
    key: unSlash(RoutePath.ADMIN) + RoutePath.INSURANCE_APPLICATION,
    icon: React.createElement(Note2Icon),
    label: '修理同意書'
  },
  {
    key: unSlash(RoutePath.ADMIN) + RoutePath.CUSTOMERS,
    icon: React.createElement(GroupIcon),
    label: '顧客'
  },
  {
    key: unSlash(RoutePath.REPAIRS),
    icon: React.createElement(ToolIcon),
    label: '修理',
    disabled: false
  },
  {
    key: unSlash(RoutePath.ADMIN) + RoutePath.ACCOUNT,
    icon: React.createElement(NoteIcon),
    label: 'アカウント'
  },
  {
    key: '',
    icon: React.createElement(SettingIcon),
    label: '設定',
    children: [
      {
        key: unSlash(RoutePath.ADMIN) + RoutePath.CONFIG,
        label: '権限設定'
      },
      {
        key: unSlash(RoutePath.ADMIN) + RoutePath.MARKERS,
        label: 'メーカー名マスター'
      },
      {
        key: unSlash(RoutePath.ADMIN) + RoutePath.MODELS,
        label: '型番マスター'
      },
      {
        key: unSlash(RoutePath.ADMIN) + RoutePath.PRODUCTS,
        label: '製品カテゴリーマスター'
      }
    ]
  }
];

const StyledMenu = styled(Menu)`
  border-inline-end: none !important;

  .ant-menu-submenu {
    .ant-menu-submenu-title {
      margin-inline: 0px;
      margin-block: 0px;
      width: 100%;
      border-radius: 0px;
      color: #8c8c8c;
      font-weight: 600;
    }
  }

  .ant-menu-item {
    margin-inline: 0px;
    margin-block: 0px;
    width: 100%;
    border-radius: 0px;
    color: #8c8c8c;
    font-weight: 600;

    &.ant-menu-item-selected {
      position: relative;
      background-color: rgba(82, 114, 189, 0.15);
      color: #5272bd;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        border-left: 3px solid #5272bd !important;
      }
    }
  }
`;

const SideMenu: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const collectKey = (
    menuItems: MenuProps['items'],
    result: { [key: string]: string }
  ) => {
    menuItems?.forEach((item) => {
      const key = item?.key?.toString() || '';
      result[key] = key;
      const parseItem = item as any;
      if (isArray(parseItem?.children)) {
        collectKey(parseItem?.children, result);
      }
    });
    return result;
  };
  const [searchParams] = useSearchParams() 

  const patternKeys = useMemo(() => {
    const result: { [key: string]: string } = {};
    collectKey(menuItems, result);
    return result;
  }, [menuItems]);

  const arrayPathname = location.pathname.split('/');
  let key = '';
  arrayPathname.reverse().forEach((_, index) => {
    const keyPath = [...arrayPathname].splice(index, arrayPathname.length);
    if(searchParams.get('dontHighLight') === 'true') return key
    const keyString = keyPath
      .reverse()
      .join('/')
      .replace('/', '') as keyof typeof patternKeys;

    if (patternKeys[keyString] && !key)
      key = patternKeys[keyString];
  });

  const handleMenuClick: MenuProps['onClick'] = (info) => {
    navigate(info.key);
  };

  const goToSubmitRepairAgreementPage = () => {
    navigate(`${RoutePath.ADMIN + RoutePath.INSURANCE_APPLICATION + RoutePath.NEW}?dontHighLight=true`);
  };
  return (
    <aside className="h-full relative">
      <div className="mt-2 mb-1 px-2">
        <Button
          type="primary"
          className="w-full rounded-lg font-bold py-[18px]"
          onClick={goToSubmitRepairAgreementPage}
        >
          修理同意送信
        </Button>
      </div>
      <StyledMenu
        mode="inline"
        items={menuItems}
        onClick={handleMenuClick}
        selectedKeys={[key]}
      />
    </aside>
  );
};

export default SideMenu;
