import { notifyMessage } from 'src/constants/message';
import { notification } from 'core-ui';
import { isString } from 'lodash';
import { dayjs } from 'core-helpers';

export const handleError = (error: any) => {
  console.log({ error });

  const resMessage = error?.response?.data?.errors;
  const errorMessage = isString(resMessage) ? resMessage : notifyMessage.failed;

  notification.error({
    message: errorMessage
  });
};
export function objectToFormData(obj: any, form = null, namespace = '') {
  const formData = form || new FormData();

  for (const property in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, property)) {
      const value = obj[property];
      // Kiểm tra để loại bỏ giá trị undefined và null
      if (value === undefined || value === null || property === '') continue;

      const formKey = namespace ? `${namespace}[${property}]` : property;
      if (dayjs.isDayjs(value)) {
        formData.append(formKey, value.toString());
      } else if (Array.isArray(value)) {
        value.forEach((element, index) => {
          if (
            typeof element === 'object' &&
            !(element instanceof File) &&
            !(element instanceof Blob)
          ) {
            objectToFormData(element, formData as any, `${formKey}[${index}]`);
          } else {
            formData.append(`${formKey}[${index}]`, element);
          }
        });
      } else if (
        typeof value === 'object' &&
        !(value instanceof File) &&
        !(value instanceof Blob)
      ) {
        objectToFormData(value, formData as any, formKey);
      } else {
        formData.append(formKey, value);
      }
    }
  }

  return formData;
}
export function trimObjectValues(obj: any) {
  for (let key in obj) {
    if (typeof obj[key] === 'string') {
      obj[key] = obj[key].trim();
    } else if (
      typeof obj[key] === 'object' &&
      !Array.isArray(obj[key]) &&
      obj[key] !== null
    ) {
      trimObjectValues(obj[key]);
    }
  }
  return obj;
}
