import { Layout } from 'core-ui';
import { Outlet } from 'react-router-dom';
import Header from './components/Header';
import Logo from './components/Logo';
import SideMenu from './components/SideMenu';
import { useEffect, useState } from 'react';
import MobileDrawer from './components/MobileDrawer';
import useResponsive from 'src/hooks/useResponsive';
import { getCustomerProfile } from 'src/services/customer/profile';

const CustomerLayout = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [userName, setUserName] = useState('');

  useEffect(() => {
    const getProfile = async () => {
      try {
        const { data } = await getCustomerProfile();
        if (data) {
          setUserName(data.name);
        }
      } catch (error) {}
    };

    getProfile();
  }, []);

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const { isMobile } = useResponsive();

  useEffect(() => {
    if (!isMobile) {
      setOpenDrawer(false);
    }
  }, [isMobile]);

  return (
    <Layout
      headerContent={
        <Header handleOpenDrawer={handleOpenDrawer} userName={userName} />
      }
      logoContent={<Logo />}
      defaultSiderWidth={220}
      hideCollapser
      contentStyle={{
        overflow: 'auto'
      }}
      headerStyle={{
        height: isMobile ? 64 : 56
      }}
      customSider={<SideMenu />}
      siderClassName="max-md:hidden"
    >
      <MobileDrawer
        open={openDrawer}
        onClose={handleCloseDrawer}
        userName={userName}
      />
      <Outlet />
    </Layout>
  );
};

export default CustomerLayout;
