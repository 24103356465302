import { customerApiRequest } from 'src/utils/api';
import { ApiPath } from 'src/enums/apiPath';

export const getCustomerProfile = async () => {
  const res = await customerApiRequest.get({
    url: ApiPath.CUSTOMER_GET_ME
  });

  return res.data;
};
