import { BrowserRouter } from 'react-router-dom';
import AppRouter from './router';
import { Provider } from 'react-redux';
import ModalProvider from './contexts/modalContext';
import DrawerProvider from './contexts/drawerContext';
import { store } from './redux/store';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ConfigProvider, ja_JP } from 'core-ui';
import theme from './theme';
import { Helmet } from 'react-helmet';
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const queryClient = new QueryClient();

const App = () => {
  return (
    <>
      <Helmet>
        <meta
          name="robots"
          content={process.env.REACT_APP_ROBOT_CONTENT || 'noindex,nofollow'}
        />
      </Helmet>
      <ConfigProvider
        locale={ja_JP}
        theme={theme}
        button={{ autoInsertSpace: false }}
        form={{
          validateMessages: {
            required: '${label}は必須です。',
            types: {
              email: '${label}が無効な形式です。'
            },
            whitespace: '${label}は必須です。'
          }
        }}
      >
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <Provider store={store}>
              <ModalProvider>
                <DrawerProvider>
                  <AppRouter />
                </DrawerProvider>
              </ModalProvider>
            </Provider>
          </QueryClientProvider>
        </BrowserRouter>
      </ConfigProvider>
    </>
  );
};

export default App;
