import { useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { PrivateRouteI } from 'src/models/router';
import { getFallbackPath } from 'src/utils/route';
import { isAdminLoggined, isCustomerLoggined } from 'src/utils/auth';
import { AuthRole } from 'src/enums/auth';

export const PrivateRoute = ({ children, role }: PrivateRouteI) => {
  const isLogined = useMemo(() => {
    if (role === AuthRole.ADMIN) {
      return isAdminLoggined();
    }
    if (role === AuthRole.CUSTOMER) {
      return isCustomerLoggined();
    }
  }, [role]);
  const location = useLocation();
  const fallbackPath = getFallbackPath(location.pathname);

  return isLogined ? children : <Navigate to={fallbackPath} replace />;
};
