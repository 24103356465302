import MainLayout from 'src/layouts/MainLayout';
import { lazy } from 'react';
import { RouteI } from 'src/models/router';
import { RoutePath } from 'src/enums/routePath';
import UserNotLogin from 'src/layouts/UserNotLogin';
import CustomerLayout from 'src/layouts/Customer';
import InsuranceAgreementLayout from 'src/layouts/InsuranceAgreement';
import { AuthRole } from 'src/enums/auth';
import ConfigController from 'src/pages/admin/Config/components/controller';

const ConfigPage = import('../pages/admin/Config');
const AdminLoginPage = import('../pages/admin/Login');
const CustomerLoginPage = import('../pages/customer/Login');
const AdminInsuranceApplicationCreate = import(
  '../pages/admin/SubmitAgreement/create'
);
const AdminInsuranceApplicationEdit = import(
  '../pages/admin/SubmitAgreement/edit'
);
const AdminInsuranceApplication = import('../pages/admin/SubmitAgreement');
const AdminAccount = import('../pages/admin/Account');
const AdminCreateAccount = import('../pages/admin/Account/create');
const AdminCustomerList = import('../pages/admin/Customers');
const AdminDashboard = import('../pages/admin/Dashboard');
const AdminEditAccount = import('../pages/admin/Account/edit');
const AdminAccountDetail = import('../pages/admin/Account/detail');
const AdminCreateCustomer = import('../pages/admin/Customers/create');
const AdminEditCustomer = import('../pages/admin/Customers/edit');
const AdminDetailCustomer = import('../pages/admin/Customers/detail');
const ForgotPasswordPage = import('../pages/ForgotPassword');
const ResetPasswordPage = import('../pages/ResetPassword');
const InsuranceAgreementDetailPage = import(
  '../pages/customer/InsuranceApplicationConfirm/entry-pages/InsuranceAgreementDetail'
);
const DocumentViewerPage = import(
  '../pages/customer/InsuranceApplicationConfirm/public-pages/DocumentViewer'
);
const CustomerAgreementListPage = import(
  '../pages/customer/InsuranceApplicationConfirm/logged-in-pages/AgreementList'
);
const AdminRepairList = import('../pages/admin/Repairs');
const RepairCreatePage = import('../pages/admin/Repairs/create');
const RepairEditPage = import('../pages/admin/Repairs/edit');
const RepairDetailPage = import('../pages/admin/Repairs/detail');
const MarkerList = import('../pages/admin/Markers');
const ProductCategoryList = import('../pages/admin/ProductCategory');
const ModelList = import('../pages/admin/Models');
const CustomerHistoryListPage = import('../pages/customer/InsuranceApplicationConfirm/logged-in-pages/History/HistoryList');
const CustomerHistoryDetailPage = import('../pages/customer/InsuranceApplicationConfirm/logged-in-pages/History/HistoryDetail');

export const routes: RouteI[] = [
  {
    path: RoutePath.ADMIN + RoutePath.LOGIN,
    Component: lazy(() => AdminLoginPage)
  },
  {
    path: RoutePath.EMPTY,
    Component: lazy(() => AdminDashboard),
    Layout: <MainLayout />,
    isPrivate: true,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.ADMIN,
    Component: lazy(() => AdminDashboard),
    Layout: <MainLayout />,
    isPrivate: true,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.FORGOT_PASSWORD,
    Component: lazy(() => ForgotPasswordPage)
  },
  {
    path: RoutePath.RESET_PASSWORD,
    Component: lazy(() => ResetPasswordPage)
  },
  {
    path: RoutePath.ADMIN + RoutePath.ACCOUNT,
    Component: lazy(() => AdminAccount),
    Layout: <MainLayout />,
    isPrivate: true,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.ADMIN + RoutePath.ACCOUNT + RoutePath.NEW,
    Component: lazy(() => AdminCreateAccount),
    Layout: <MainLayout />,
    isPrivate: true,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.ADMIN + RoutePath.CUSTOMERS + RoutePath.NEW,
    Component: lazy(() => AdminCreateCustomer),
    Layout: <MainLayout />,
    isPrivate: true,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.ADMIN + RoutePath.CUSTOMERS + RoutePath.ID + RoutePath.EDIT,
    Component: lazy(() => AdminEditCustomer),
    Layout: <MainLayout />,
    isPrivate: true,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.ADMIN + RoutePath.CUSTOMERS + RoutePath.ID,
    Component: lazy(() => AdminDetailCustomer),
    Layout: <MainLayout />,
    isPrivate: true,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.ADMIN + RoutePath.ACCOUNT + RoutePath.ID,
    Component: lazy(() => AdminAccountDetail),
    Layout: <MainLayout />,
    isPrivate: true,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.ADMIN + RoutePath.ACCOUNT + RoutePath.ID + RoutePath.EDIT,
    Component: lazy(() => AdminEditAccount),
    Layout: <MainLayout />,
    isPrivate: true,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.ADMIN + RoutePath.INSURANCE_APPLICATION + RoutePath.NEW,
    Component: lazy(() => AdminInsuranceApplicationCreate),
    Layout: <MainLayout />,
    isPrivate: true,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.ADMIN + RoutePath.INSURANCE_APPLICATION,
    Component: lazy(() => AdminInsuranceApplication),
    Layout: <MainLayout />,
    isPrivate: true,
    role: AuthRole.ADMIN
  },
  {
    path:
      RoutePath.ADMIN +
      RoutePath.INSURANCE_APPLICATION +
      RoutePath.ID +
      RoutePath.EDIT,
    Component: lazy(() => AdminInsuranceApplicationEdit),
    Layout: <MainLayout />,
    isPrivate: true,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.CUSTOMER + RoutePath.INSURANCE_APPLICATION + RoutePath.ID,
    Layout: <InsuranceAgreementLayout />,
    Component: lazy(() => InsuranceAgreementDetailPage)
  },
  {
    path:
      RoutePath.CUSTOMER +
      RoutePath.INSURANCE_APPLICATION +
      RoutePath.ID +
      RoutePath.REPAIR_PATH +
      RoutePath.REPAIR_ID +
      RoutePath.DOCUMENT_VIEWER +
      RoutePath.DOCUMENT_ID,
    Layout: <UserNotLogin />,
    Component: lazy(() => DocumentViewerPage)
  },
  {
    path:
      RoutePath.ADMIN +
      RoutePath.REPAIR_PATH +
      RoutePath.REPAIR_ID +
      RoutePath.DOCUMENT_VIEWER +
      RoutePath.DOCUMENT_ID,
    Layout: <UserNotLogin />,
    Component: lazy(() => DocumentViewerPage)
  },
  {
    path: RoutePath.ADMIN + RoutePath.CUSTOMERS,
    Component: lazy(() => AdminCustomerList),
    Layout: <MainLayout />,
    isPrivate: true,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.CUSTOMER + RoutePath.LOGIN,
    Component: lazy(() => CustomerLoginPage)
  },
  {
    path: RoutePath.CUSTOMER + RoutePath.INSURANCE_APPLICATION,
    Component: lazy(() => CustomerAgreementListPage),
    isPrivate: true,
    role: AuthRole.CUSTOMER,
    Layout: <CustomerLayout />
  },
  {
    path: RoutePath.CUSTOMER_HISTORY,
    Component: lazy(() => CustomerHistoryListPage),
    isPrivate: true,
    role: AuthRole.CUSTOMER,
    Layout: <CustomerLayout />
  },
  {
    path: RoutePath.CUSTOMER_HISTORY + RoutePath.ID,
    Component: lazy(() => CustomerHistoryDetailPage),
    isPrivate: true,
    role: AuthRole.CUSTOMER,
    Layout: <CustomerLayout />
  },
  {
    path: RoutePath.REPAIRS,
    Component: lazy(() => AdminRepairList),
    Layout: <MainLayout />,
    isPrivate: true,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.REPAIRS_CREATE,
    Component: lazy(() => RepairCreatePage),
    Layout: <MainLayout />,
    isPrivate: true,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.REPAIRS_EDIT,
    Component: lazy(() => RepairEditPage),
    Layout: <MainLayout />,
    isPrivate: true,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.REPAIRS_DETAIL,
    Component: lazy(() => RepairDetailPage),
    Layout: <MainLayout />,
    isPrivate: true,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.ADMIN + RoutePath.MARKERS,
    Component: lazy(() => MarkerList),
    Layout: <MainLayout />,
    isPrivate: true,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.ADMIN + RoutePath.PRODUCTS,
    Component: lazy(() => ProductCategoryList),
    Layout: <MainLayout />,
    isPrivate: true,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.ADMIN + RoutePath.MODELS,
    Component: lazy(() => ModelList),
    Layout: <MainLayout />,
    isPrivate: true,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.ADMIN + RoutePath.CONFIG,
    Component: lazy(() => ConfigPage),
    Layout: <MainLayout />,
    isPrivate: true,
    role: AuthRole.ADMIN
  },
  {
    path: RoutePath.ADMIN + RoutePath.CONFIG + RoutePath.NEW,
    Component: ConfigController,
    Layout: <MainLayout />,
    isPrivate: true,
    role: AuthRole.ADMIN
  },

  {
    path: RoutePath.ADMIN + RoutePath.CONFIG + RoutePath.ID,
    Component: ConfigController,
    Layout: <MainLayout />,
    isPrivate: true,
    role: AuthRole.ADMIN
  }
];
