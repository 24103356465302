import { Popover } from 'core-ui';
import Cookies from 'js-cookie';
import { RoutePath } from 'src/enums/routePath';
import { ACCESS_TOKEN_KEY } from 'src/constants/auth';
import useHandleNetwork from 'src/hooks/useHandleNetwork';
import { AvatarIcon, DownOutlinedIcon } from 'src/assets/icons';
import { useEffect, useState } from 'react';
import { getAdminProfile } from 'src/services/admin/profile';

const Header = () => {
  const handleLogout = () => {
    Cookies.remove(ACCESS_TOKEN_KEY);
    window.location.replace(RoutePath.ADMIN + RoutePath.LOGIN);
  };

  const [adminName, setAdminName] = useState('');

  useEffect(() => {
    const getProfile = async () => {
      try {
        const { data } = await getAdminProfile();
        if (data) {
          setAdminName(data.name);
        }
      } catch (error) {}
    };

    getProfile();
  }, []);

  return (
    <div className="h-[100%] flex items-center justify-end px-[20px] bg-primary">
      <div className="flex items-center">
        <Popover
          placement="bottomRight"
          content={
            <div className="min-w-[70px]">
              <p
                onClick={handleLogout}
                className="cursor-pointer w-full text-[#EA3D3D] text-xs text-center"
              >
                ログアウト
              </p>
            </div>
          }
        >
          <div className="flex cursor-pointer items-center">
            <div>
              <img src={AvatarIcon} alt="avatar icon" />
            </div>

            <div className="ml-3">
              <p className="leading-[20px] font-medium text-white">
                {adminName}
              </p>
            </div>

            <div className="ml-2">
              <img src={DownOutlinedIcon} alt="down outlined icon" />
            </div>
          </div>
        </Popover>
      </div>
    </div>
  );
};

export default Header;
