import { Button, Drawer } from 'core-ui';
import { AvatarIcon, LogoutIcon, MenuUnfoldIcon } from 'src/assets/icons';
import { RoutePath } from 'src/enums/routePath';
import { unSlash } from 'src/utils/route';
import { Menu, MenuProps, styled } from 'core-ui-2';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleCustomerLogout } from 'src/services/auth';

const StyledDrawer = styled(Drawer)`
  .ant-drawer-header {
    background-color: #5272bd;
    height: 64px;
    padding-left: 15px;
    padding-right: 15px;
    .ant-drawer-header-title {
      justify-content: space-between;
      .ant-drawer-title {
        flex: none;
      }
    }
  }
  .ant-drawer-body {
    padding: 10px 20px;
  }
  .ant-drawer-footer {
    padding-bottom: 28px;
    border-top: none;
  }
`;

const StyledMenu = styled(Menu)`
  border-inline-end: none !important;

  .ant-menu-item {
    margin-inline: 0px;
    margin-block: 0px;
    width: 100%;
    border-radius: 0px;
    color: #8c8c8c;
    font-weight: 600;
    padding-left: 8px !important;

    &.ant-menu-item-selected {
      position: relative;
      background-color: #e6f7ff;
      color: #5272bd;
    }
  }
`;

interface IProps {
  open: boolean;
  onClose: () => void;
  userName: string;
}

const MobileDrawer: React.FC<IProps> = (props) => {
  const { onClose, open, userName } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const menuItems: MenuProps['items'] = [
    {
      key: unSlash(RoutePath.CUSTOMER) + RoutePath.INSURANCE_APPLICATION,
      label: '修理同意送信'
    },
    {
      key: unSlash(RoutePath.CUSTOMER_HISTORY),
      label: '修理履歴'
    },
    {
      key: '',
      label: '個人情報管理',
      disabled: true
    }
  ];

  const handleMenuClick: MenuProps['onClick'] = (info) => {
    navigate(info.key);
  };

  return (
    <StyledDrawer
      open={open}
      onClose={onClose}
      placement="left"
      closeIcon={<img src={MenuUnfoldIcon} alt="menu unfold icon" />}
      width="300px"
      title={
        <div className="flex cursor-pointer items-center">
          <div>
            <img src={AvatarIcon} alt="avatar icon" className="w-[40px]" />
          </div>

          <div className="ml-3">
            <p className="leading-[20px] font-medium text-white text-[14px]">
              {userName}
            </p>
          </div>
        </div>
      }
      footer={
        <Button
          className="text-[#EC221F] border border-[#EC221F] w-full py-5 text-[12px]"
          icon={<img src={LogoutIcon} alt="log out icon" />}
          onClick={handleCustomerLogout}
        >
          <span>ログアウト</span>
        </Button>
      }
    >
      <StyledMenu
        mode="inline"
        items={menuItems}
        onClick={handleMenuClick}
        selectedKeys={[location.pathname.replace('/', '')]}
      />
    </StyledDrawer>
  );
};

export default MobileDrawer;
