import { combineReducers } from '@reduxjs/toolkit';
import manufacturerSlice from './manufacturer/manufacturerSlice';
import productModelSlice from './productModel/productModelSlice';
import productCategorySlice from './productCategory/productCategorySlice';
import customerAgreementSlice from './customerAgreement/customerAgreementSlice';

const rootReducer = combineReducers({
  [manufacturerSlice.name]: manufacturerSlice.reducer,
  [productModelSlice.name]: productModelSlice.reducer,
  [productCategorySlice.name]: productCategorySlice.reducer,
  [customerAgreementSlice.name]: customerAgreementSlice.reducer
});

export default rootReducer;
