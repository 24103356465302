import React, { useMemo } from 'react';
import { getIsLoggined, isCustomerLoggined } from 'src/utils/auth';
import CustomerLayout from '../Customer';
import UserNotLogin from '../UserNotLogin';

const InsuranceAgreementLayout = () => {
  const isLogined = useMemo(() => {
    return isCustomerLoggined();
  }, []);

  if (isLogined) {
    return <CustomerLayout />;
  }

  return <UserNotLogin />;
};

export default InsuranceAgreementLayout;
