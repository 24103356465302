const ReduxSliceNames = {
  LoadingFullScreen: 'LoadingFullScreen',
  Manufacturers: 'Manufacturers',
  ManufacturersFetchList: 'Manufacturers/fetchList',
  ProductModels: 'ProductModels',
  ProductModelsFetchList: 'ProductModels/fetchList',
  ProductCategories: 'ProductCategories',
  ProductCategoriesFetchList: 'ProductCategories/fetchList',
  CustomerAgreement: 'CustomerAgreement',
  CustomerAgreementFetchNotReadCount: 'CustomerAgreement/fetchNotReadCount'
};

export default ReduxSliceNames;
