import { AgreementConfirmStatus } from 'src/enums/agreementStatus';
import { API_URL, ApiPath } from 'src/enums/apiPath';
import { RepairDocumentStatus } from 'src/enums/documentStatus';
import { QueryParams } from 'src/models/apiRequest';
import {
  IManufacturerQuery,
  IProductCategoryQuery,
  IProductModelQuery
} from 'src/models/insurance';
import { publicApiRequest } from 'src/utils/api';

export const getInsuranceAgreementDetail = async (
  id: string,
  token?: string
) => {
  const res = await publicApiRequest.get({
    url: ApiPath.PUBLIC_AGREEMENTS + `/${id}`,
    params: {
      token
    },
    headers: {
      'accept-language': 'ja'
    }
  });

  return res.data;
};

export const getPublicManufacturerList = async (query: IManufacturerQuery) => {
  const res = await publicApiRequest.get({
    url: ApiPath.PUBLIC_MAKERS,
    params: {
      limit: query.limit,
      page: query.page
    }
  });

  return res.data;
};

export const getPublicProductModelList = async (query: IProductModelQuery) => {
  const res = await publicApiRequest.get({
    url: ApiPath.PUBLIC_PRODUCT_MODELS,
    params: {
      limit: query.limit,
      page: query.page,
      makerId: query.makerId
    }
  });

  return res.data;
};

export const getPublicProductCategoryList = async (
  query: IProductCategoryQuery
) => {
  const res = await publicApiRequest.get({
    url: ApiPath.PUBLIC_PRODUCT_CATEGORIES,
    params: {
      limit: query.limit,
      page: query.page,
      name: query.name
    }
  });

  return res.data;
};

export const getFileBlob = async (id: string) => {
  const response = await fetch(API_URL + ApiPath.DOWNLOAD_PDF_FILE + `/${id}`);

  return response;
};

export const getDocumentDetail = async (
  repairId: string | number,
  documentId: string | number
) => {
  const response = await publicApiRequest.get({
    url:
      ApiPath.PUBLIC_REPAIRS +
      `/${repairId}` +
      ApiPath.DOCUMENTS +
      `/${documentId}`
  });

  return response.data;
};

export const updateDocumentStatus = async (
  repairId: string | number,
  documentId: string | number,
  body: { status: RepairDocumentStatus }
) => {
  const res = await publicApiRequest.put({
    url:
      ApiPath.PUBLIC_REPAIRS +
      `/${repairId}` +
      ApiPath.DOCUMENTS +
      `/${documentId}`,
    data: body,
    headers: {
      'accept-language': 'ja'
    }
  });

  return res.data;
};

export const confirmSubmitAgreement = async (
  agreementId: string | number,
  repairId: string | number,
  data: any
) => {
  const res = await publicApiRequest.put({
    url:
      ApiPath.PUBLIC_AGREEMENTS +
      `/${agreementId}` +
      ApiPath.REPAIR +
      `/${repairId}`,
    data,
    headers: { 'Content-Type': 'multipart/form-data', 'accept-language': 'ja' }
  });

  return res.data;
};

export const updateAgreementConfirmStatus = async (
  id: string | number,
  status: AgreementConfirmStatus
) => {
  const response = await publicApiRequest.put({
    url: ApiPath.PUBLIC_AGREEMENTS + `/${id}` + ApiPath.STATUS,
    data: {
      status
    }
  });

  return response.data;
};

export const sendConfirmToAdmin = async (
  agreementId: string | number,
  repairId: string | number
) => {
  const res = await publicApiRequest.put({
    url:
      ApiPath.PUBLIC_AGREEMENTS +
      `/${agreementId}` +
      ApiPath.REPAIR +
      `/${repairId}` +
      ApiPath.CONFIRM,
    headers: {
      'accept-language': 'ja'
    }
  });

  return res.data;
};
