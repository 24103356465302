import {
  Button,
  Checkbox,
  Form,
  Input,
  notification,
  Tree,
  TreeDataNode
} from 'core-ui';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Footer from 'src/components/Footer';
import StyledForm from 'src/components/styled-form';
import { RoutePath } from 'src/enums/routePath';
import { apiRequest } from 'src/utils/api';
import { trimObjectValues } from 'src/utils/common';
export default function ConfigController() {
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const onSubmit = async () => {
    let formData = form.getFieldsValue();
    formData.permissions = formData.permissions.filter(
      (key: string) => !key.includes('[parent]')
    );
    formData = trimObjectValues(formData);
    try {
      const { data } = await apiRequest.put({
        url: !id ? '/api/admin/positions' : `/api/admin/positions/${id}`,
        data: formData,
        method: id ? 'put' : 'post'
      });
      notification.success({ message: data.message });
      navigate(RoutePath.ADMIN + RoutePath.CONFIG);
    } catch (error: any) {
      notification.error({
        message:
          error.response?.data.message ||
          error.response?.data?.error?.[0].message
      });
    }
  };
  const fetchData = async () => {
    if (!id) return;
    const { data } = await apiRequest.get({
      url: `/api/admin/positions/${id}`
    });
    form.setFieldsValue(data.data);
    form.setFieldValue(
      'permissions',
      data.data.permissions.map((i: any) => i.permission)
    );
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <StyledForm
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      style={{ background: 'rgb(242, 242, 242)' }}
    >
      <div className="bg-[#fff] h-[68px] pt-[12px] pb-[16px] px-[24px] ">
        <p className="font-[600] text-[24px]">
          {id ? '役割編集' : '役割新規作成'}
        </p>
      </div>
      <div className="bg-[#fff] mt-[16px] p-[16px] m-[20px]">
        <Input
          rules={[{ required: true }]}
          size="large"
          name="name"
          label="役割"
          style={{ maxWidth: 368 }}
          placeholder="役割"
        />
        <Form.Item
          valuePropName="checkedKeys"
          name="permissions"
          label="権限設定"
          trigger="onCheck"
        >
          <Tree
            defaultExpandAll
            checkable
            treeData={treeData}
            onCheck={console.log}
          />
        </Form.Item>
      </div>
      <Footer>
        <Button
          style={{ height: 45, width: 168 }}
          onClick={() => navigate(RoutePath.ADMIN + RoutePath.CONFIG)}
        >
          {id ? 'キャンセル' : '戻る'}
        </Button>
        <Button
          style={{ height: 45, width: 168 }}
          type="primary"
          onClick={form.submit}
        >
          {id ? '保存' : '作成'}
        </Button>
      </Footer>
    </StyledForm>
  );
}

const treeData: TreeDataNode[] = [
  {
    title: '保険金代理請求合意申請送信',
    key: 'submit_agreement',
    children: []
  },
  {
    title: '顧客管理',
    key: '[parent]customer',
    children: [
      {
        title: '顧客一覧',
        key: 'customer_list'
      },
      {
        title: '顧客新規作成',
        key: 'customer_create'
      },
      { title: '顧客詳細', key: 'customer_detail' },
      { title: '顧客編集', key: 'customer_edit' }
    ]
  },
  {
    title: '修理管理',
    key: '[parent]repair',
    children: [
      { title: '修理管理', key: 'repair_list' },
      { title: '修理新規作成', key: 'repair_create' },
      { title: '修理詳細', key: 'repair_detail' },
      { title: '修理編集', key: 'repair_edit' }
    ]
  },
  {
    title: 'アカウント管理',
    key: '[parent]account',
    children: [
      { title: 'アカウント管理', key: 'account_list' },
      { title: 'アカウント新規作成', key: 'account_create' },
      { title: 'アカウント詳細', key: 'account_detail' },
      { title: 'アカウント編集', key: 'account_edit' }
    ]
  },
  {
    title: '設定',
    key: '[parent]permission',
    children: [
      { title: '権限設定', key: 'permission_list' },
      {
        title: '修理同意書のリンクの有効設定',
        key: 'enabling_repair_agreement_link'
      }
    ]
  }
];
